import React, { useEffect, useRef, useState, createRef } from 'react';
import { Color } from 'material-ui-color';
import ScreenShareOutlinedIcon from '@material-ui/icons/ScreenShareOutlined';
import * as faceapi from 'face-api.js';
import { useLive } from '../../hooks/live';
import {
  Container,
  AlertLive,
  SharedScreenLive,
  Compartilhamento,
  ImagemFundo,
  Imagem,
  FrenteImagem,
} from './styles';
import { useSocket } from '../../hooks/socket';
import imagem_fundo from '../../assets/imagem_fundo.png';
import logoIMG from '../../assets/lagoinha_preto.png';

interface AtualizaTelao {
  socketId: string;
  libras: boolean;
  textoAlerta: string;
  corFundo1: Color;
  corFundo2: Color;
  corFonte: Color;
  alerta: boolean;
  screenLoaded: boolean;
  screenActivated: boolean;
  fundo: boolean;
}

interface SocketMensagem {
  id: string;
  atualizacao: string;
}

let predictedAges: any[] = [];

const LivePage: React.FC = () => {
  const {
    alertActived,
    alertText,
    alertBackgroundColor1,
    alertBackgroundColor2,
    alertForegroundColor,
    libras,
    setAlertText,
    screen,
    screenActivated: sharedActivated,
    setAlertActived,
    setAlertBackgroundColor1,
    setAlertBackgroundColor2,
    setAlertForegroundColor,
    setLibras,
    setScreen,
    setScreenActivated,
    imagemFundo,
    setImagemFundo,
  } = useLive();
  const { conectarSocket, socket } = useSocket();
  const [faceCarregada, setFaceCarregada] = useState(false);
  const videoRef = useRef<HTMLVideoElement>(null);
  const video = createRef<HTMLVideoElement>();

  useEffect(() => {
    conectarSocket('tasilva@gmail.com');
  }, [conectarSocket]);

  useEffect(() => {
    if (socket) {
      socket.on('telao', (data: SocketMensagem) => {
        console.log('[SOCKET] Recebi Atualização Telão');
        const { atualizacao } = data;
        const atualizacaoTelao = JSON.parse(atualizacao) as AtualizaTelao;
        const {
          libras: libraONOFF,
          textoAlerta,
          corFundo1,
          corFundo2,
          corFonte,
          alerta,
          screenLoaded,
          screenActivated,
          fundo,
        } = atualizacaoTelao;
        setScreen(screenLoaded);
        setScreenActivated(screenActivated);
        setAlertText(textoAlerta);
        setImagemFundo(fundo);
        setAlertBackgroundColor1(corFundo1);
        setAlertBackgroundColor2(corFundo2);
        setAlertForegroundColor(corFonte);
        setLibras(libraONOFF);
        setAlertActived(alerta);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socket]);

  useEffect(() => {
    Promise.all([
      faceapi.nets.tinyFaceDetector.loadFromUri('/models'),
      faceapi.nets.faceLandmark68Net.loadFromUri('/models'),
      faceapi.nets.faceRecognitionNet.loadFromUri('/models'),
      faceapi.nets.faceExpressionNet.loadFromUri('/models'),
      faceapi.nets.ageGenderNet.loadFromUri('/models'),
      faceapi.nets.ssdMobilenetv1.loadFromUri('/models'),
    ]).then(async () => {
      setFaceCarregada(true);
      try {
        const mediaStream = await navigator.mediaDevices.getUserMedia({
          video: {},
        });

        if (video && video.current !== null) {
          video.current.srcObject = mediaStream;
        }
      } catch (e: any) {
        console.log(e.name, e.message, e.stack);
      }
    });

    if (video && video.current !== null) {
      video.current.addEventListener('playing', async () => {
        if (video.current !== null) {
          const canvas = faceapi.createCanvasFromMedia(video.current);
          document.body.append(canvas);
          const labeledFaceDescriptors = await loadLabeledImages();
          const faceMatcher = new faceapi.FaceMatcher(
            labeledFaceDescriptors,
            0.6,
          );

          const width = window.screen.availWidth;
          const height = window.screen.availWidth * 0.5625;
          const displaySize = {
            width,
            height,
          };
          faceapi.matchDimensions(canvas, displaySize);

          setInterval(async () => {
            if (video.current !== null) {
              const detections = await faceapi
                .detectAllFaces(
                  video.current,
                  new faceapi.TinyFaceDetectorOptions(),
                )
                .withFaceLandmarks()
                .withFaceExpressions()
                .withAgeAndGender()
                .withFaceDescriptors();
              const resizedDetections = faceapi.resizeResults(
                detections,
                displaySize,
              );
              const results = resizedDetections.map(d =>
                faceMatcher.findBestMatch(d.descriptor),
              );
              canvas
                .getContext('2d')
                ?.clearRect(0, 0, canvas.width, canvas.height);
              results.forEach((result, i) => {
                faceapi.draw.drawDetections(canvas, resizedDetections[i]);
                // faceapi.draw.drawFaceLandmarks(canvas, resizedDetections[i]);
                faceapi.draw.drawFaceExpressions(canvas, resizedDetections[i]);

                const { label } = result;
                const topRight = {
                  x:
                    resizedDetections[i].detection.box.topRight.x -
                    43 -
                    label.length,
                  y: resizedDetections[i].detection.box.topRight.y - 23,
                };
                new faceapi.draw.DrawTextField([`${label}`], topRight).draw(
                  canvas,
                );

                const { age } = resizedDetections[i];
                const interpolatedAge = interpolateAgePredictions(age);
                const bottomRight = {
                  x: resizedDetections[i].detection.box.bottomRight.x - 50,
                  y: resizedDetections[i].detection.box.bottomRight.y,
                };
                new faceapi.draw.DrawTextField(
                  [`${faceapi.utils.round(interpolatedAge, 0)} anos`],
                  bottomRight,
                ).draw(canvas);
              });
            }
          }, 100);
        }
      });
    }
  }, [video]);

  // useEffect(() => {
  //   Promise.all([
  //     faceapi.nets.tinyFaceDetector.loadFromUri('/models'),
  //     faceapi.nets.faceLandmark68Net.loadFromUri('/models'),
  //     faceapi.nets.faceRecognitionNet.loadFromUri('/models'),
  //     faceapi.nets.faceExpressionNet.loadFromUri('/models'),
  //     faceapi.nets.ageGenderNet.loadFromUri('/models'),
  //     faceapi.nets.ssdMobilenetv1.loadFromUri('/models'),
  //   ]).then(() => setFaceCarregada(true));

  //   if (video) {
  //     video.addEventListener('playing', async () => {
  //       const canvas = faceapi.createCanvasFromMedia(
  //         video,
  //       ) as HTMLCanvasElement;
  //       document.body.append(canvas);

  //       const labeledFaceDescriptors = await loadLabeledImages();
  //       const faceMatcher = new faceapi.FaceMatcher(
  //         labeledFaceDescriptors,
  //         0.6,
  //       );

  //       const displaySize = { width: video.width, height: video.height };
  //       faceapi.matchDimensions(canvas, displaySize);

  //       setInterval(async () => {
  //         const detections = await faceapi
  //           .detectAllFaces(video, new faceapi.TinyFaceDetectorOptions())
  //           .withFaceLandmarks()
  //           .withFaceExpressions()
  //           .withAgeAndGender()
  //           .withFaceDescriptors();
  //         const resizedDetections = faceapi.resizeResults(
  //           detections,
  //           displaySize,
  //         );
  //         const results = resizedDetections.map(d =>
  //           faceMatcher.findBestMatch(d.descriptor),
  //         );

  //         const context = canvas.getContext('2d');
  //         context?.clearRect(0, 0, canvas.width, canvas.height);
  //         faceapi.draw.drawDetections(canvas, resizedDetections);
  //         faceapi.draw.drawFaceLandmarks(canvas, resizedDetections);
  //         faceapi.draw.drawFaceExpressions(canvas, resizedDetections);

  //         const { label } = results[0];
  //         const topRight = {
  //           x: resizedDetections[0].detection.box.topRight.x - 50,
  //           y: resizedDetections[0].detection.box.topRight.y - 23,
  //         };

  //         new faceapi.draw.DrawTextField([`${label}`], topRight).draw(canvas);

  //         const { age } = resizedDetections[0];
  //         const interpolatedAge = interpolateAgePredictions(age);
  //         const bottomRight = {
  //           x: resizedDetections[0].detection.box.bottomRight.x - 50,
  //           y: resizedDetections[0].detection.box.bottomRight.y,
  //         };

  //         new faceapi.draw.DrawTextField(
  //           [`${faceapi.utils.round(interpolatedAge, 0)} anos`],
  //           bottomRight,
  //         ).draw(canvas);
  //       }, 100);
  //     });
  //   }
  // }, []);

  const interpolateAgePredictions = (age: any): any => {
    predictedAges = [age].concat(predictedAges).slice(0, 30);
    const avgPredictedAge =
      predictedAges.reduce((total, a) => total + a) / predictedAges.length;
    return avgPredictedAge;
  };

  const loadLabeledImages = (): any => {
    const labels = ['Thiago'];
    return Promise.all(
      labels.map(async label => {
        const descriptions: any[] = [];
        for (let i = 1; i <= 2; i += 1) {
          // eslint-disable-next-line no-await-in-loop
          const img = await faceapi.fetchImage(
            `https://face.tassolucoes.com.br/labeled_images/${label}/${i}.jpg`,
          );
          // eslint-disable-next-line no-await-in-loop
          const detections = await faceapi
            .detectSingleFace(img)
            .withFaceLandmarks()
            .withFaceDescriptor();
          descriptions.push(detections?.descriptor);
        }

        return new faceapi.LabeledFaceDescriptors(label, descriptions);
      }),
    );
  };

  const shareScreen = async (): Promise<void> => {
    const opts = {
      audio: false,
      video: true,
      width: { ideal: 1920 },
      height: { ideal: 1080 },
    };
    const stream = await navigator.mediaDevices.getDisplayMedia(opts);
    if (videoRef.current !== null) {
      videoRef.current.srcObject = stream;
      if (sharedActivated) {
        videoRef.current.hidden = false;
        videoRef.current.play();
      }
    }
  };

  useEffect(() => {
    if (videoRef.current !== null && !screen) {
      videoRef.current.hidden = true;
      videoRef.current.srcObject = null;
    }
  }, [screen]);

  useEffect(() => {
    if (
      videoRef.current !== null &&
      screen &&
      sharedActivated &&
      !imagemFundo
    ) {
      try {
        videoRef.current.hidden = false;
        videoRef.current.play();
      } catch {
        console.log('Erro ao dar play');
      }
    } else if (
      videoRef.current !== null &&
      screen &&
      (!sharedActivated || imagemFundo)
    ) {
      try {
        videoRef.current.pause();
        videoRef.current.hidden = true;
      } catch {
        console.log('Erro ao parar');
      }
    }
  }, [screen, sharedActivated, imagemFundo]);

  useEffect(() => {
    if (
      videoRef &&
      videoRef.current !== null &&
      (!screen || !sharedActivated || imagemFundo)
    ) {
      videoRef.current.hidden = true;
    }
  }, [videoRef, screen, sharedActivated, imagemFundo]);

  return (
    <>
      <Container>
        <ImagemFundo hidden={!imagemFundo}>
          <Imagem src={imagem_fundo} alt="IEQ Lagoinha" />
        </ImagemFundo>
        <FrenteImagem hidden={!imagemFundo}>
          <img src={logoIMG} alt="IEQ Lagoinha" />
          <p>Apaixonada por almas</p>
          <p>Apaixonada pela Presença de Deus</p>
        </FrenteImagem>
        {faceCarregada && (
          <div
            style={{ width: '100vw', height: '100vh', position: 'relative' }}
          >
            <video
              ref={video}
              autoPlay
              muted
              style={{
                position: 'absolute',
                width: '100%',
                left: 0,
                right: 0,
                bottom: 0,
                top: 0,
              }}
            />
            <canvas
              id="canvas"
              style={{ position: 'absolute', width: '640', height: '480' }}
            />
          </div>
        )}
        <SharedScreenLive ref={videoRef} />
        {alertActived && (
          <AlertLive
            corTexto={`#${alertForegroundColor.hex}`}
            corFundo1={`#${alertBackgroundColor1.hex}`}
            corFundo2={`#${alertBackgroundColor2.hex}`}
            widthScreen={`${window.screen.availWidth}px`}
            libras={libras}
          >
            <p>{alertText}</p>
          </AlertLive>
        )}
        {screen && (
          <Compartilhamento>
            <ScreenShareOutlinedIcon onClick={shareScreen} />
          </Compartilhamento>
        )}
      </Container>
    </>
  );
};

export { LivePage };
